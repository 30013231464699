<template>
  <div>
    <v-container
      fluid
      class="border-top pa-0 pt-5 pb-9"
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div class="
              display-flex
              align-items-center
              justify-content-center
              height-100
              width-100
            ">
            <div
              v-if="imageFile !== null || this.sImgEdit !== null"
              class="content-image-articles"
            >
              <v-btn
                @click="editImg"
                icon
                class="global-button-edit-item"
              >
                <v-icon size="20px"> mdi-pencil </v-icon>
              </v-btn>
              <img
                v-if="this.sImgEdit !== null"
                class="global-img-src"
                :src="this.sImgEdit"
              />
              <img
                v-else
                class="global-img-src"
                :src="maskedFile(imageFile)"
              />
            </div>
            <div v-else>
              <v-btn
                @click="$refs.inputUpload.click()"
                class="button-add-document"
                elevation="0"
              >
                <!-- <div v-if="imageFile !== null" class="content-image-articles">
                  <img :src="imageFile_data" alt="" />
                </div> -->
                <div
                  class="content-image-articles-empty"
                  :style="
                    imageFile_data !== null
                      ? { display: 'none' }
                      : { display: 'block' }
                  "
                >
                  <v-icon
                    color="#B2B2B2"
                    size="60px"
                  > mdi-camera </v-icon>
                </div>
              </v-btn>
            </div>
            <input
              ref="inputUpload"
              style="display: none"
              type="file"
              size="60"
              accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
              @change="uploadFilesWarehouse"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <div>
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <v-text-field
                      v-model="enterpriseName"
                      type="text"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader1
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader1
                      "
                      persistent-placeholder
                      maxLength="60"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div>
                    <v-text-field
                      v-model="enterpriseEmail"
                      type="text"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader3
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader3
                      "
                      persistent-placeholder
                      maxLength="85"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div>
                    <v-text-field
                      v-model="enterpriseRFC"
                      type="text"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader12
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader12
                      "
                      persistent-placeholder
                      maxLength="13"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div>
                    <v-text-field
                      v-model="enterprisePhone"
                      type="text"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader4
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader4
                      "
                      persistent-placeholder
                      maxLength="13"
                      @keypress="soloNumbers"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <v-textarea
                      v-model="enterpriseDescription"
                      class="global-inputs mon-regular"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader11
                      "
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader11
                      "
                      outlined
                      color="#000000"
                      dense
                      maxLength="250"
                    ></v-textarea>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="7"
        >
          <div>
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <div class="content-input-map">
                      <p class="content-input-map-label mon-regular">
                        {{ texts.userprofile.dialogEditEnterprise.textHeader2 }}
                      </p>
                      <GmapAutocomplete
                        @place_changed="setPlace"
                        autocomplete=""
                        :value="enterpriseAddress"
                        :options="autocompleteOptions"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <v-text-field
                      v-model="enterpriseAddressOptional"
                      type="text"
                      :label="
                        selectLanguage == 'sp'
                          ? 'Dirección específica'
                          : 'Specific address'
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        selectLanguage == 'sp'
                          ? 'Dirección específica'
                          : 'Specific address'
                      "
                      persistent-placeholder
                      maxLength="150"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <v-text-field
                      v-model="enterpriseCity"
                      type="text"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader5
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader5
                      "
                      persistent-placeholder
                      maxLength="150"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <v-text-field
                      v-model="enterprisePostalCode"
                      type="text"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader8
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader8
                      "
                      persistent-placeholder
                      maxLength="5"
                      @keypress="soloNumbers"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="pb-1"
                  cols="12"
                  md="6"
                >
                  <div>
                    <v-select
                      v-model="enterpriseCountry"
                      :items="itemsCountries"
                      item-text="sName"
                      item-value="sCountryId"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader7
                      "
                      outlined
                      color="#000000"
                      dense
                      @change="emptyState()"
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader7
                      "
                    >
                    </v-select>
                    <!-- @input="selecState" -->
                  </div>
                </v-col>
                <v-col
                  class="pb-1"
                  cols="12"
                  md="6"
                >
                  <div>
                    <v-select
                      v-model="enterpriseState"
                      :items="itemsStates"
                      item-text="sName"
                      item-value="sStateId"
                      :label="
                        texts.userprofile.dialogEditEnterprise.textHeader6
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        texts.userprofile.dialogEditEnterprise.textHeader6
                      "
                    >
                    </v-select>
                    <!-- @input="selecState" -->
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <GmapMap
            :center="center"
            :zoom="4"
            :options="mapOptions"
            style="width: 100%; height: 200px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center = m.position"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="border-bottom" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div
            v-if="isResponsive"
            class="mt-5"
          >
            <v-btn
              @click="saveImages"
              class="button-primary mon-bold"
              elevation="0"
              :loading="bLoading"
              :disabled="!validateForm"
              style="width: 100%;"
            >
              {{ texts.userprofile.tab2.buttons.save }}
            </v-btn>
            <v-btn
              @click="closeDialog"
              class="button-secondary mon-regular mt-5"
              elevation="0"
              style="width: 100%;"
            >
              {{ texts.userprofile.tab2.buttons.cancel }}
            </v-btn>
          </div>
          <div
            v-else
            class="ajust-button display-flex align-items-center"
          >
            <v-btn
              @click="closeDialog"
              class="button-secondary mon-regular"
              elevation="0"
            >
              {{ texts.userprofile.tab2.buttons.cancel }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="saveImages"
              class="button-primary mon-bold ml-1"
              elevation="0"
              :loading="bLoading"
              :disabled="!validateForm"
            >
              {{ texts.userprofile.tab2.buttons.save }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "UserProfileDialogEditPassword",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
    img: { type: String },
  },
  data() {
    return {
      // information enterprise
      enterpriseName: "",
      enterpriseAddress: "",
      enterpriseAddressOptional: "",
      enterpriseEmail: "",
      enterprisePhone: "",
      enterpriseDescription: "",
      enterpriseRFC: "",

      // location enterprise
      itemsCountries: [],
      itemsStates: [],
      enterpriseCity: "",
      enterpriseState: "",
      enterpriseCountry: "",
      enterprisePostalCode: "",
      enterpriseLongitude: "",
      enterpriseLatitude: "",

      //VARIABLES
      bLoading: false,

      // google maps
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },

      // logo image
      imageFile_data: null,
      imageFile: null,
      imageName: "",
      logoImage: "",
      sImgEdit: null,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getEnterpriseData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    editImg: function () {
      this.$refs.inputUpload.click();
    },
    maskedFile: function (file) {
      const objectURL = URL.createObjectURL(file);
      return objectURL;
    },
    closeDialog() {
      this.$emit("changeSwitchEdit", false);
    },
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;
      this.sImgEdit = null;
      this.imageFile = null;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile_data = fr.result;
          this.imageFile = files[0];
          this.imageName = files[0].name;
        });
      }
    },
    saveImages: function () {
      if (this.imageFile_data !== null) {
        this.bLoading = true;
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        var form = new FormData();
        form.append("image", this.imageFile);

        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/logo`,
          form,
          config
        )
          .then((response) => {
            this.saveChanges();
          })
          .catch((error) => {
            this.bLoading = false;

            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.saveChanges();
      }
    },
    saveChanges: function () {
      const payload = {
        sName: this.enterpriseName,
        sLocationAddress: this.enterpriseAddress,
        sLocationOptionalAddress: this.enterpriseAddressOptional,
        sEmail: this.enterpriseEmail,
        sPhoneNumber: this.enterprisePhone,
        sLocationCity: this.enterpriseCity,
        sLocationStateId: this.enterpriseState,
        sDescription: this.enterpriseDescription,
        sLocationZipCode: this.enterprisePostalCode,
        dLocationLatitude: this.enterpriseLatitude,
        dLocationLongitude: this.enterpriseLongitude,
        sTaxpayerId: this.enterpriseRFC,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setPlace: function (place) {
      this.enterpriseCity = "";
      this.enterprisePostalCode = "";

      var dom = document.createElement("div");
      dom.innerHTML = place.adr_address;
      let postal_code_html = dom.getElementsByClassName("postal-code"),
        locality_html = dom.getElementsByClassName("locality");
      this.enterprisePostalCode = postal_code_html[0].innerHTML;
      this.enterpriseCity = locality_html[0].innerHTML;

      let split_name = place.formatted_address.split(",");
      let name_address = split_name[0] + "," + split_name[1];

      this.enterpriseAddress = name_address;
      this.currentPlace = place;
      this.addMarker();
    },
    addMarker: function () {
      this.markers = [];

      if (this.currentPlace) {
        this.enterpriseLatitude = this.currentPlace.geometry.location.lat();
        this.enterpriseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    getEnterpriseData: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;

          //data location
          this.logoImage = arr.oVendorLogo.oImages.lg;
          this.sImgEdit = arr.oVendorLogo.oImages.lg
            ? arr.oVendorLogo.oImages.lg
            : null;
          this.enterpriseName = arr.sName;
          this.enterpriseAddress = arr.sLocationAddress;
          this.enterpriseAddressOptional = arr.sLocationOptionalAddress;
          this.enterpriseEmail = arr.sEmail;
          this.enterprisePhone = arr.sPhoneNumber;
          this.enterpriseCity = arr.sLocationCity;
          this.enterprisePostalCode = arr.sLocationZipCode;
          this.enterpriseLongitude = arr.dLocationLongitude;
          this.enterpriseLatitude = arr.dLocationLatitude;
          this.enterpriseDescription = arr.sDescription;
          this.enterpriseCountry = arr.sCountryId;
          this.enterpriseState = arr.sStateId;
          this.enterpriseRFC = arr.sTaxpayerId;

          const marker = {
            lat: arr.dLocationLatitude,
            lng: arr.dLocationLongitude,
          };
          this.markers.push({ position: marker });
          this.center = marker;
          this.currentPlace = null;
          this.getCountries();

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCountries: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`)
        .then((response) => {
          this.itemsCountries = response.data.results;
          // this.getStates();
        })
        .catch((error) => {});
    },
    selecState: function (id) {
      this.getStates(id);
    },
    getStates: function (id) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${id}/states`
      )
        .then((response) => {
          this.itemsStates = [];
          this.itemsStates = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    emptyState() {
      this.enterpriseState = null;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.enterpriseName !== "" &&
        this.enterpriseAddress !== "" &&
        this.enterpriseEmail !== "" &&
        this.enterprisePhone !== "" &&
        this.enterpriseCity !== "" &&
        this.enterpriseState !== "" &&
        this.enterpriseCountry !== "" &&
        this.enterprisePostalCode !== "" &&
        this.enterpriseLongitude !== "" &&
        this.enterpriseLatitude !== "" &&
        this.enterpriseDescription !== "" &&
        this.enterpriseRFC !== "" &&
        this.enterpriseName !== null &&
        this.enterpriseAddress !== null &&
        this.enterpriseEmail !== null &&
        this.enterprisePhone !== null &&
        this.enterpriseCity !== null &&
        this.enterpriseState !== null &&
        this.enterpriseCountry !== null &&
        this.enterprisePostalCode !== null &&
        this.enterpriseLongitude !== null &&
        this.enterpriseLatitude !== null &&
        this.enterpriseDescription !== null
      );
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
      }
    },
    tab: function () {
      if (this.tab != 1) {
        this.closeDialog();
      }
    },
    enterpriseCountry() {
      this.selecState(this.enterpriseCountry);
    },
  },
};
</script>

<style scoped>
.content-input-map-label {
  font-size: 10px;
  position: absolute;
  top: -8px;
  left: 12px;
  color: #00000090;
}

.content-input-map {
  position: relative;
}

.content-input-map input {
  width: 100%;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 14px;
  font-family: "monserrat-regular", sans-serif !important;
  padding: 0px 15px;
  letter-spacing: 0px;
  color: #000000;
}

.content-input-map input:focus-visible {
  outline: none;
}

.content-input-map input::-webkit-input-placeholder {
  color: #fff;
}

.content-input-map input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input:-ms-input-placeholder {
  color: #fff;
}

.button-add-document {
  width: 230px;
  height: 230px !important;
  border-radius: 100%;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 3px !important;
}

.content-image-articles {
  position: relative;
  width: 230px;
  height: 230px !important;
  border-radius: 100%;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 3px !important;
}

.content-image-articles img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

@supports (object-fit: cover) {
  .content-image-articles img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-image-articles-empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .content-image-articles-empty img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

@supports (object-fit: cover) {
  .content-image-articles-empty img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
} */

.button-gray {
  width: 170px;
  height: 40px !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-yellow {
  width: 170px;
  height: 40px !important;
  background: transparent linear-gradient(259deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-result {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.border-top {
  border-top: 1px solid #e0e0e0;
}

@media (max-width: 500px) {
  .ajust-button {
    display: block;
  }
  .ajust-button button {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
